.mainDiv {
  box-sizing: border-box;
  background-color: #fff;
  padding: 30px 30px 60px;
  width: 100%;
  /* min-height: 500px; */
}
.mapBox {
  height: 80vh;
}

.imgContainer {
  display: flex;
}
.imgContainer img {
  width: 50px;
}
.listContainer {
  display: flex;
}

.listDiv {
  margin-top: 20px;
  margin-right: 120px;
}

.listDiv h6 {
  color: grey;
  font-size: 0.8rem;
  font-weight: 700;
}
.listDiv p {
  /* color: #f6a507;
  font-size: 0.8rem; */
  font-weight: 600;
}
/* .descContainer {
  width: 50%;
} */
.descContainer h6 {
  color: grey;
  font-size: 0.8rem;
  font-weight: 700;
}
.descContainer p {
  font-weight: 600;
}
.timeContainer {
  margin-right: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.timeContainer h6 {
  color: grey;
  font-size: 0.6rem;
  font-weight: 500;
  margin-top: 10px;
}
.timeContainer h5 {
  font-size: 0.6rem;
  font-weight: 800;
  /* margin-top: 10px; */
}

.receiptBox {
  padding: 20px 10px;
  width: 343px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  border: 1px solid grey;
}
.receiptBox img {
  width: 100px;
}

.formBox {
  /* border: 1px solid red; */
  height: 300px;
  width: 60%;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.formBox section {
  margin-bottom: 30px;
}
.inpBox {
  margin: 10px 0;
}

.sidebar {
  /* border: 1px solid red; */
  position: fixed;
  height: 91vh;
  width: 220px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 8px;
  padding-top: 20px;
}
.sidebarLinksBox {
  display: flex;
  cursor: pointer;
}
.linkLine {
  width: 7px;
  height: 50px;
}
.sideLinkText {
  position: relative;
  top: 12px;
  width: 100%;
  padding-left: 20px;
  font-size: 16px;
}
.text {
  position: relative;
  top: 1px;
  left: 8px;
}
.icon {
  font-size: 22px;
  color: gray;
}

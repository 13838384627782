.mainContainer {
  /* background-color: #fff; */
}
.nameInput {
  padding: 5px 3px;
  color: #505050;
  font-size: 13px;
}
.inputGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 3px;
  color: #505050;
  font-size: 13px;
}
.radioGroup {
  display: flex;
  /* justify-content: space-between; */
  /* width: 100%; */
  margin-right: 20px;
  padding: 5px 3px;
  color: #505050;
  font-size: 13px;
}
.input {
  width: 48%;
}
.radioContainer {
  display: flex;
}
.radioInp {
  width: auto;
  color: #6e528d !important;
}
.radioTxt {
  margin-left: 10px;
}

.placeContainerActive {
  position: absolute;
  margin-top: 8px !important;
  min-height: 10px;
  max-height: 300px !important;
  width: 100% !important;
  z-index: 999 !important;
  background-color: white;
  top: 0;
  overflow: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.placeList {
  list-style: none;
  padding: 10px 5px;
  cursor: pointer;
}

.placeList:hover {
  background-color: rgb(214, 215, 222);
}

.loading {
  position: absolute;
  background-color: white;
  width: 100%;
  text-align: center;
  margin-top: 8px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  z-index: 999 !important;
  padding-block: 10px;
}
.placeInput {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  border: 1px solid lightgray;
  font-size: 1rem;
  color: gray;
  margin: 30px 0;
}
.placeInput ::placeholder {
  color: lightgray;
}

.placeContainerActive {
  position: absolute;
  margin-top: 8px !important;
  min-height: 10px;
  max-height: 300px !important;
  width: 100% !important;
  z-index: 999 !important;
  background-color: white;
  top: 0;
  overflow: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.placeList {
  list-style: none;
  padding: 10px 5px;
  cursor: pointer;
}

.placeList:hover {
  background-color: rgb(214, 215, 222);
}

.loading {
  position: absolute;
  background-color: white;
  width: 100%;
  text-align: center;
  margin-top: 8px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  z-index: 999 !important;
  padding-block: 10px;
}

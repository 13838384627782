.mainContainer {
  /* background-color: #fff; */
}
.nameInput {
  padding: 5px 3px;
  color: #505050;
  font-size: 13px;
}
.fileUpload {
  width: 120px;
  padding: 25px 0px;
  color: #505050;
  font-size: 13px;
  flex-direction: column;
  display: flex;
  align-items: center;
  border: 1px solid grey;
  border-radius: 10px;
}
.file {
  display: none;
}
.inputGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 3px;
  color: #505050;
  font-size: 13px;
}
.radioGroup {
  display: flex;
  /* justify-content: space-between; */
  /* width: 100%; */
  margin-right: 20px;
  padding: 5px 3px;
  color: #505050;
  font-size: 13px;
}
.input {
  width: 48%;
}
.radioContainer {
  display: flex;
}
.radioInp {
  width: auto;
  color: #6e528d !important;
}
.radioTxt {
  margin-left: 10px;
}
.displayBox{
  width: 120px;
  padding: 15px 0px;
  color: #505050;
  font-size: 13px;
  flex-direction: column;
  display: flex;
  align-items: center;
  /* border: 1px solid grey; */
  border-radius: 10px;
}
.imgBoxLabel{
  /* border: 1px solid red; */
  width: 100px;
  height: 100px;
}
.imgBoxLabel img{
  border-radius: 10px;
}
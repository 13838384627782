.select {
  border: 1px solid grey;
  padding: 8px 10px;
  border-radius: 5px;
  color: grey;
  background-color: var(--bluecolor);
}
.select:hover {
  transition: 0.5s ease;
  background-color: #ffffff;
}
.select option {
  color: grey;
}
.select option:hover {
  background-color: #f1b200;
}

.mainBox {
  background: #ffff;
  width: auto;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
  padding: 20px 49px;
  margin-bottom: 15px;
  margin-right: 10px;
  border-radius: 5%;
  /* height: 30vh; */
}
.mainBox img {
  width: 50px;
}

.heading {
  margin-top: 17px;
  font-weight: 500;
}
.para {
  font-weight: 600;
  font-size: 27px;
}

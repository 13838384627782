.loginBox {
  display: flex;
  height: 100vh;
}
.imgBox {
  width: 45%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5fffc;
}
.imgBox img {
  max-width: 80%;
}
.formBox {
  width: 55%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}
@media only screen and (max-width: 767px) {
  .loginBox {
    flex-direction: column;
  }
  .imgBox {
    width: 100%;
    height: 30vh;
  }
  .formBox {
    width: 100%;
    height: 70vh;
  }
  .formBox form {
    width: 90%;
    align-items: center;
    justify-content: center;
  }
}

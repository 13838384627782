.barBox {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.heading {
  font-weight: 700;
}

.calenderBox {
  display: flex;
  justify-content: space-between;
}
.calenderBox img {
  width: 20px;
  height: 2vh;
  margin-right: 10px;
}

.calenderBox p {
  font-weight: 700;
}

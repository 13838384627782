.barChart {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.barChart > div {
  /* border: 1px solid blue; */
  background-color: #fff;
  padding: 10px;
  /* box-shadow: 5px 5px 20px 2px gray; */
  border-radius: 3%;
}

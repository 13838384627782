.btn {
  border: none;
  padding: 8px 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #6e528d;
}
.btn:hover {
  transition: 0.5s ease;
  background-color: #574170;
}

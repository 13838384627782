.imgContainer {
  display: flex;
}
.imgContainer img {
  width: 200px;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 10px;
}
.listContainer {
  display: flex;
}

.listDiv {
  margin-top: 20px;
  margin-right: 120px;
}

.listDiv h6 {
  color: grey;
  font-size: 0.8rem;
  font-weight: 700;
}
.listDiv p {
  /* color: #f6a507;
  font-size: 0.8rem; */
  font-weight: 600;
}
/* .descContainer {
  width: 50%;
} */
.descContainer h6 {
  color: grey;
  font-size: 0.8rem;
  font-weight: 700;
}
.descContainer p {
  font-weight: 600;
}

.imgContainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
}
.imgContainer img {
  width: 50px;
}
.imgContainer2{
  /* border: 1px solid red; */
  width: 150px;
  height: 150px;
}
.imgContainer2 img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.timeContainer {
  /* border: 1px solid black; */
  margin-right: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.timeContainer h6 {
  color: grey;
  font-size: 0.6rem;
  font-weight: 500;
  margin-top: 10px;
}
.timeContainer h5 {
  font-size: 0.6rem;
  font-weight: 800;
  /* margin-top: 10px; */
}

.listContainer {
  display: flex;
}

.listDiv {
  margin-top: 20px;
  margin-right: 120px;
}

.listDiv h6 {
  color: grey;
  font-size: 0.8rem;
  font-weight: 700;
}
.listDiv p {
  /* color: #f6a507;
  font-size: 0.8rem; */
  font-weight: 600;
}
/* .descContainer {
  width: 50%;
} */
.descContainer h6 {
  color: grey;
  font-size: 0.8rem;
  font-weight: 700;
}
.descContainer p {
  font-weight: 600;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  padding-right: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: sticky;
  top: 0;
  background-color: #6e528d;
  color: #fff;
  z-index: 999;
}
.navbar img {
  /* margin-left: 10px; */
}
.user {
  /* padding: 5px 5px; */
  /* text-align: center; */
  border-radius: 50%;
  /* background-color:#6E528D; */
  background-color: #fff;
}
.user h4 {
  position: relative;
  top: 6px;
  color: #fff;
  font-weight: 600;
}
.btn {
  background-color: transparent;
  outline: none;
  border: none;
}
@media only screen and (max-width: 310px) {
  .navbar {
    padding-right: 20px;
  }
}

.Calender{
  /* border: 1px solid red; */
}
.react-calendar {
    width: 45% !important;
    max-width: 100%;
    background-color: #ece8e8;
    color: #222;
    border-radius: 8px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border: none !important;
  }
  .react-calendar__tile:disabled {
    background-color: #fff !important;
  }
  .react-calendar__tile--now {
    background: lightgray !important;
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    /* border: 1px solid red; */
    text-decoration: none;
    color: #505050;
    font-size: 0.9em;
  }
  .react-calendar__navigation {
    height: 40px !important;
  }
  .react-calendar__navigation button {
    color: #000;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
  }
  .react-calendar__tile {
    /* min-width: 10% !important; */
    min-height: 55px !important;
    border-radius: 25px;
    font-size: 0.9em !important;
  
    /* border: 1px solid red !important; */
  }
  
  .react-calendar__tile--active {
    background-color: #6e528d !important;
  }
  @media only screen and (max-width: 551px) {
    .react-calendar__tile--active {
      /* min-width: 40px; */
      /* height: 20px !important; */
    }
    .react-calendar__tile--now {
      min-width: 40px;
    }
    .react-calendar {
      min-width: 100% !important;
    }
  }
  @media only screen and (max-width: 380px) {
    .react-calendar__tile--active {
      /* height: 20px !important; */
    }
    .react-calendar__tile{
      min-height: 40px !important;
    }
    .react-calendar__tile--now {
      min-width: 42px;
    }
    .react-calendar {
      min-width: 100% !important;
    }
  }
  @media only screen and (max-width: 280px) {
    .react-calendar__navigation__label__labelText {
      position: relative;
      right: 11px;
      text-align: center;
    }
  }
.tableParent {
  max-height: 72vh;
  overflow: auto;
}
.tableParent table {
  /* overflow: auto; */
  width: 100%;
  min-width: max-content;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  margin-top: 10px;

  /* max-height: 60vh; */
}
.tableParent table thead {
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 111;
}
.tableParent table td {
  max-width: 240px;
  padding: 10px 18px;
  color: #505050;
  font-size: 15px;
}

.actionTd span {
  border: 1px solid#6E528D;
  padding: 3px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
}
.actionTd span svg {
  color: #6e528d;
  font-size: 1.2rem;
}
.checkBox {
  border: 1px solid black;
  width: 18px;
  height: 18px;
  position: relative;
  top: 5px;
  margin-right: 10px;
  border-radius: 5px;
  padding: 3px;
  accent-color: #6e528d;
  background-color: #fff;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: gray;
}
::-webkit-scrollbar-thumb {
  background-color: #6e528d;
  border-radius: 10px;
  /* width: 15px; */
  opacity: 0.5;
  transition: 0.5s;
}
::-webkit-scrollbar-thumb:hover {
  transition: 0.5s;
  background-color: #6e528d;
}
.tHeading {
  font-weight: 700;
  font-size: 1.1rem;
}
.category span {
  padding: 5px 0;
  text-align: center;
  display: inline-block;
  width: 120px;
  border-radius: 5px;
  color: #fff;
}
